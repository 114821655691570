.reportDetailContainer {
  padding: 22px;
}

.para {
  font-size: 22px;
  line-height: 18px;
  .heading {
    font-weight: bold;
  }
}
.inspection-item-table tr th,
.inspection-item-table tr td {
  width: 30%;
}

.inspection-item-table tr th:nth-child(3),
.inspection-item-table tr td:nth-child(3) {
  width: 5%;
}

.inspection-item-table tr th:nth-child(4),
.inspection-item-table tr td:nth-child(4) {
  width: 35%;
}
.inspection-item-image{
    height: 150px;
    width: 150px;
}

.image-row {
  page-break-inside: avoid;
}