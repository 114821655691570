.graph-header {
  padding: 12px 32px 0px 32px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px #cccccc solid;
  background-color: #f2f2f2;
  flex-wrap: wrap;
}
.report-graph-search {
  padding-top: 18px;
  background-color: #f2f2f2;
}
.search-items {
  height: fit-content;
  @media (max-width: 575px) {
    margin-bottom: 16px;
  }
}
.arrow-back-btn {
  border: none;
  text-transform: capitalize;
  background-color: transparent;
  margin-top: -10px;

  i {
    color: #333;
    display: flex;
    align-items: center;
  }
  p {
    margin-left: 5px;
    margin-top: 16px;
  }

  &:hover {
    background-color: #e2e6ea;
  }
}
.graph-container {
  width: 98%;
  min-height: 400px;
  box-sizing: border-box;
}

.loader-container {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.norecord-container p {
  padding-top: 2rem;
}

.btn-close {
  padding: 10px;
}

@media screen and (max-width: 575px) {
  .average-score {
    text-align: center;
  }
  .btn-close {
    margin-top: -15px;
    text-align: center;
    margin-bottom: 8px;
  }
}
