.cleanerWorkLoadList {
  max-height: 83vh;
  overflow-y: auto;
  .header {
    background-color: #f2f2f2;
    color: #000;
    font-weight: bold;
    font-size: 18;
  }
  .item {
    font-size: 15;
  }
  .remove-link {
    display: none;
  }
  .item:hover {
    background-color: #f2f2f2 !important;
  }

  .item:hover .remove-link {
    display: inline-block;
    cursor: pointer;
    font-size: 15;
    text-decoration: underline;
  }
}
.workloadTableContainer{
  padding: 5px;
  margin: 5px;
  max-height: 83vh;
  overflow-y: auto;
}
.workloadTable {
   height: 100%;
  .remove-link {
    display: inline-block;
  visibility: hidden;
  }
  .item:hover .remove-link {
   visibility:unset;
    cursor: pointer;
    text-decoration: underline;
  }
  .cursor{
    cursor: pointer;
  }
}
.workLoadTitle{
  font-weight: 600;
  font-size: 1.5rem;
  @media (max-width: 576px) {
    text-align: center;
  }
}
