/*
@media screen  and (min-width: 1024px){
  .page-content {
    max-height: 100vh;
  }
  .dashboard .main-content-tabs-section,
  .page-content {
    display: flex;
    flex-flow: column nowrap;
  }
  .overview .tablet-view-sticky-img-nav,
  .main-nav {
    flex: 0 0 auto;

  }

  .tab-content,
  .dashboard {
    flex: 1 1 auto;

  }
}
*/

.greyout {
  filter: grayscale(50) !important;
}
.dashboard {
  display: flex;
  //height: 100vh;
  #siderbar {
    border-right: 1px solid rgba(5, 36, 37, 0.12);
    min-width: 420px;
    //width: 100%;
    .section-header {
      min-height: 180px;
      border-bottom: 1px solid rgba(5, 36, 37, 0.12);
      .user-button {
        margin-left: 15px;
      }
    }
    .bottom-header {
      .header-content {
        h3 {
          font-size: 24px;
          font-weight: 300;
          color: rgba(5, 36, 37, 0.87);
          margin: 0;
        }
      }
      .header-button {
        .btn {
          color: #136162;
          border: 1px solid rgba(5, 36, 37, 0.12);
          img {
            margin-right: 10px;
          }
          &:hover {
            border: 1px solid #136162;
            background-color: #fff;
          }
        }
      }
    }
  }
}
.inner-section {
  color: rgba(5, 36, 37, 0.87);
  letter-spacing: 0.18px;
  max-height: 100vh;
  overflow: auto;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-top: 0 !important;
  }
  .tabs-holder {
    .nav-tabs {
      .nav-item {
        flex: 1;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          padding: unset;
        }
        .nav-link {
          font-size: 14px;
          color: rgba(5, 36, 37, 0.38);
          text-transform: uppercase;
          width: 100%;
          &:hover,
          &.active {
            color: #136162;
          }
        }
      }
    }

    .add-admin-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 32px);
      position: absolute;
      bottom: 0;
      padding: 11px;
      margin: 32px 0;
      color: #136162;
      font-size: 16px;
      line-height: 13px;
      border: 1px solid rgba(5, 36, 37, 0.38);
      background: #ffffff;
      border-radius: 4px;
      text-transform: uppercase;
      &:hover {
        box-shadow: none;
      }

      &#admin {
        .icon-holder {
          .error-icon,
          .delete-icon {
            cursor: pointer;
            margin-right: 5px;
            display: none;
          }
        }
        ul {
          li {
            min-height: 59px;
            &:hover {
              background: rgba(5, 36, 37, 0.08);
              .icon-holder {
                max-width: 65px;
                .error-icon,
                .delete-icon {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    #siderbar {
      min-width: 100%;
    }
  }
}


.qck-card {
  background: #ffffff;
  border: 1px solid rgba(5, 36, 37, 0.12);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  min-height: 212px;
  overflow: hidden;
  .qck-card-card-image-container {
      height: 172px;
  }
  .qck-card-image {
  }
  .qck-card-footer {
    min-height: 40px;
    border-top: 1px solid rgba(5, 36, 37, 0.12);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .dropdown-menu li a {
    font-size: 14px;
  }
}
.main-content {
  height: 100vh;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .main-content {
    .district-holder {
      justify-content: space-evenly;
    }
  }
}
@media screen and (max-width: 576px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
.tans {
  transform: translate3d(-194px, 15px, 0px) !important;
}

.logo-uploader-area {
  background: #f2f6f6;
  border-radius: 8px;
  padding: 10px;
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(5, 36, 37, 0.6);
}

.modal {
  &#add_School {
    .modal-holder {
      max-width: 550px;
      margin: 0 auto;
      .modal-content {
        border-radius: 12px;
        box-shadow: 0px 12px 17px #00000024, 0px 5px 22px #0000001f,
          0px 7px 8px #00000033;
        .modal-body {
          max-width: 550px;
          padding: 40px;
          .modal-title {
            color: rgba(5, 36, 37, 0.87);
            font-weight: normal;
            font-size: 34px;
            margin-bottom: 15px;
            text-align: center !important;
          }
          .modal-details {
            max-width: 335px;
            text-align: center;
            color: rgba(5, 36, 37, 0.87);
            margin-bottom: 30px;
          }
          .logo-uploader {
            .logo-uploader-header {
              color: rgba(5, 36, 37, 0.6);
              .btn {
                font-size: 14px;
                color: #136162;
                border: 1px solid rgba(5, 36, 37, 0.12);
              }
            }
            .logo-uploader-area {
              background: #f2f6f6;
              border-radius: 8px;
              padding: 10px;
              min-height: 148px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: rgba(5, 36, 37, 0.6);
            }
          }
          .btn.btn-primary {
            min-width: 100px;
          }
        }
      }
    }
  }
}

.deleteModal {
  width: 1000px;
}
//mobile view large btn without background without border
.add-admin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  padding: 11px;
  margin: 27px 0;
  color: #136162;
  font-size: 16px;
  line-height: 13px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;

  &:hover {
    box-shadow: none;
  }
}
//mobile view large btn without background
.add-admin-btn-mobileView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  position: absolute;
  bottom: 0;
  padding: 11px;
  margin: 32px 0;
  color: #136162;
  font-size: 16px;
  line-height: 13px;
  border: 1px solid rgba(5, 36, 37, 0.38);
  background: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;

  &:hover {
    box-shadow: none;
  }
}
//mobile view large btn for max-content modal
.btn-large-without-background-max-content-modal {
  position: unset;
  bottom: unset;
  width: 100%;
}

//mobile view large-btn-with background
.btn-large-with-background {
  width: 100%;
  margin: 32px 0 13px;
  &:hover {
    box-shadow: none;
  }
}
.toggleLabel {
  color: #264141;
  margin-left: 10px;
}
.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 52px;
  > input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }
  > input[type='checkbox'] + .switch > .switch-handle {
    transform: translateX(0px);
  }
  > input[type='checkbox']:checked + .switch > .switch-handle {
    transform: translateX(calc(100% + 10px));
  }
  > input[type='checkbox']:disabled + .switch {
    background: #ccc;
  }
  .checkedBtn {
    background-color: #136162;
  }
  .nonCheckedBtn {
    background-color: grey;
  }
  .switch {
    color: #fff;
    display: inline-block;
    width: 100%;
    transition: 100ms linear;
    position: relative;
    > .switch-handle {
      background: #fff;
      border-radius: 50%;
      display: inline-block;
      transition: 100ms linear;
      height: 20px;
      left: 0px;
      position: relative;
      top: 2px;
      width: 20px;
      z-index: 3;
    }
  }
}
//mobile view 2 buttons in modal
.edit-district-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;
  #save-btn {
    width: 159px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
  }
  #cancel-btn {
    width: 159px;
    background: #ffffff;
    color: #136162;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
  }
}

//tow button
.tablet-view-modal-btn {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .btn {
      width: 301px !important;
    }
  }
}
//------------------------------Tablet view css------------------------------
.dashboard-tablet-view-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    display: flex;
    height: calc(100vh - 180px);

    .sidebar-wrapper {
      width: 375px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
    .inner-content-wrapper {
      width: calc(100% - 375px);
      margin: 24px 32px;
    }
  }
}
//------------------------------End of tablet view css------------------------------
.dropdown.btn {
  position: relative;
  &::before {
    content: " ";
    width: 50px;
    height: 50px;
    position: absolute;  
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0px);
    display: block;
    z-index: 1000;
  }

}

.app-main-content .main-content {
  height: auto;
  overflow: initial;

}