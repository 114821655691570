.roomFeaturesTable {
    tr {
        vertical-align: middle;
    }
  thead tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  tbody tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}
