$spaceamounts: (5, 10, 15, 20, 25, 30);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side == 'all' {
      .m#{$space} {
        margin: #{$space}px;
      }

      .p#{$space} {
        padding: #{$space}px;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}