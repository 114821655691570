.overview {
  .modal-title {
    text-align: center;
  }

  .text-align-end {
    text-align: end;
  }
  .nav-item {
    @media only screen and (max-width: 1024px) {
      padding: unset;
    }
  }
  .btn.btn-outline-secondary {
    color: #136162;
    border: 1px solid rgba(5, 36, 37, 0.12);

    &:hover {
      border: 1px solid #136162;
      background-color: #fff;
    }
  }

  .title-name {
    color: #136162;
    font-size: 16px;
    font-weight: 500;
  }

  .building-card-holder {
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid rgba(5, 36, 37, 0.12);

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &:first-child {
        margin-top: 15px;
      }
    }
    &:hover {
      background: #b4dbdb;
    }

    &:focus {
      background: #b4dbdb;
    }

    &:active {
      background: #b4dbdb;
    }

    .building-card-footer {
      h6 {
        font-size: 14px;
        text-transform: uppercase;
        color: #136162;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .building-card-holder {
      &:last-child {
        margin-bottom: 95px;
      }
    }
  }

  .building-image {
    position: relative;

    .building-heading-back-arrow {
      cursor: pointer;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 8px !important;
      }
    }

    .building-heading-grade {
      font-size: 48px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-weight: 500;
        font-size: 30px;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }

    .building-caption {
      position: absolute;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.4) 40.1%,
        rgba(0, 0, 0, 0.5) 57.29%,
        rgba(0, 0, 0, 0.6) 72.92%
        );
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;

      .caption-holder {
        width: 100%;
        color: #fff;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          padding-right: 5px !important;
          padding-left: 5px !important;
        }

        h3 {
          font-size: 34px;
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin: 0;
          }
        }

        .total-grad-text {
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
        }
        .inspection-btn-grad-wrapper {
          @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
          }
          .send-email-btn {
            @media screen and (max-width: 1024px) {
              margin-right: 0 !important;
              margin-top: 4px !important;
              margin-bottom: -10px !important;
            }
          }
        }
        .go-back-btn-text {
          font-weight: 500 !important;
          margin-left: 8px;
        }

        .room-section-text {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #ffffff;
          padding-top: 6px !important;
        }
      }

      .building-details-holder {
        color: rgba(255, 255, 255, 0.74);
      }
    }
  }

  .main-content {
    overflow: auto;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      overflow: unset;
      height: auto;
    }

    .main-content-tabs-section {
      .tab-content {
        .tab-pane {
          height: inherit;

          &.people-tab-holder {
            .tabs-siderbar {
              .tab-pane {
                ul {
                  .list-item {
                    border-bottom: 1px solid rgba(5, 36, 37, 0.12);
                    padding: 11px 15px;
                    color: rgba(5, 36, 37, 0.87);
                    
                    &:nth-child(1) {
                      border-top: 1px solid rgba(5, 36, 37, 0.12);
                      // padding-top: 28px !important;
                      @media screen and (min-width: 768px) and (max-width: 1024px) {
                        border-top: unset;
                        padding-top: 16px !important;
                      }
                    }
                    &:hover {
                      background: #b4dbdb;
                      cursor: pointer;
                    }
                    &:focus {
                      background: #b4dbdb;
                      cursor: pointer;
                    }
                    &:active {
                      background: #b4dbdb;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }

        .tabs-inner-content-holder {
          display: flex;
          height: inherit;

          .tabs-siderbar {
            max-width: 415px;
            overflow: auto;
            width: 100%;
            padding: 15px;
            border-right: 1px solid rgba(5, 36, 37, 0.12);
            @media screen and (max-width: 768px) {
              max-width: 100%;
            }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
              border-right: unset;
              padding: 0 0 16px !important;
              overflow: unset;
              padding-left: 0 !important;
            }
          }

          .people-cleaner-tab-siderbar {
            max-width: 1100px;
          }

          .tabs-inner-inspection {
            max-width: 55%;
          }

          .tabs-inner-block {
            width: 100%;
            overflow: auto;

            .section-details {
              height: 100%;

              .modal-report-header {
                background-color: yellow;
              }
            }
          }
        }
      }

      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: rgba(5, 36, 37, 0.6);
          letter-spacing: 0.15px;
          font-weight: 500;
          margin: 0;
        }

        .header-button {
          .btn {
            color: #136162;
            border: 1px solid rgba(5, 36, 37, 0.12);

            &:hover {
              border: 1px solid #136162;
              background-color: #fff;
            }
          }
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .section-card-edit-btn {
            padding: 6px 7px !important;
          }
          .section-card-delete-btn {
            margin: 0 6px !important;
            padding: 6px 6px !important;
          }
        }
      }

      .inner-tab-section-list-holder {
        max-height: calc(100% - 12em);
        overflow: auto;

        .section-list-block {
          list-style: none;

          .section-without-hover {
            background: #fff !important;
          }

          .inner-tab-section-list-item {
            border-bottom: 1px solid rgba(5, 36, 37, 0.12);
            padding: 11px 15px;

            &:hover {
              background: #b4dbdb;
              cursor: pointer;
            }

            .list-label {
              color: rgba(5, 36, 37, 0.87);
              font-size: 16px;
              font-weight: normal;
            }

            .form-radio-input {
              border: 1px solid #136162;

              &:checked {
                background-color: #136162;
                box-shadow: none;
              }
            }

            .list-content {
              color: rgba(5, 36, 37, 0.87);
              font-weight: 500;
            }
          }
        }

        &.room-block {
          .inner-tab-section-list-item {
            border-radius: 12px;
            margin-bottom: 15px;
            .list-label {
              color: #1c4f51;
              font-weight: 500;
              margin-bottom: 15px;
            }

            .room-details {
              font-size: 14px;
              color: rgba(5, 36, 37, 0.6);
              margin-bottom: 5px;

              span {
                margin-right: 40px;
              }
            }
          }
        }
      }

      .inner-tab-section-list-holder-without-scroll {
        max-height: calc(100% - 12em);

        .section-list-block {
          list-style: none;

          .inner-tab-section-list-item {
            border-bottom: 1px solid rgba(5, 36, 37, 0.12);
            padding: 11px 15px;

            &:hover {
              background: #b4dbdb;
              cursor: pointer;
            }

            .list-label {
              color: rgba(5, 36, 37, 0.87);
              font-size: 16px;
              font-weight: normal;
            }

            .list-content {
              color: rgba(5, 36, 37, 0.87);
              font-weight: 500;
            }
          }
        }

        &.room-block {
          .inner-tab-section-list-item {
            border-radius: 12px;
            margin-bottom: 15px;
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
              margin-right: 0;
              margin-left: 0;
              padding: 10px;
            }

            .list-label {
              color: #1c4f51;
              font-weight: 500;
              margin-bottom: 15px;
              @media screen and (max-width: 1024px) {
                display: flex;
                justify-content: space-between;
                margin-bottom: 6px;
              }
            }

            .room-details {
              font-size: 14px;
              color: rgba(5, 36, 37, 0.6);
              margin-bottom: 5px;
              span {
                margin-right: 40px;
              }
            }
            @media screen and (max-width: 1024px) {
              .room-details {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                span {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      .section-block {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .image-holder {
          h5 {
            margin: 0;
            font-weight: 300;
            text-align: center;
            color: rgba(5, 36, 37, 0.87);
          }
        }
      }

      .section-list-holder {
        .section-list-item {
          background: #f2f6f6;
          border-radius: 12px;
          padding: 15px;
          margin-bottom: 15px;
          &:hover {
            background: #b4dbdb;
            cursor: pointer;
          }
          .header-button {
            .btn {
              &:hover {
                background: transparent;
              }
            }
          }

          .section-list-header {
            h5 {
              color: #1c4f51;
              margin: 0;
            }
          }
        }

        &.report-holder {
          .section-header {
            h6 {
              font-size: 32px;
              color: #136162;

              span {
                font-size: 16px;
              }
            }
          }

          .section-list-header {
            h5 {
              color: rgba(5, 36, 37, 0.6);
              font-size: 16px;
            }
          }
        }

        &.inspection-sidbar {
          h6 {
            font-size: 12px;
            color: #136162;
          }

          h4 {
            font-weight: 500;
            color: rgba(5, 36, 37, 0.87);
            margin-bottom: 10px;
            font-size: 20px;
          }

          .inspection-name {
            color: rgba(5, 36, 37, 0.87);
            font-weight: normal;
          }

          .grade {
            .grade-label {
              font-size: 10px;
              color: rgba(5, 36, 37, 0.38);
            }

            .grade-value {
              color: #136162;
              font-size: 36px;
            }
          }
        }
      }

      .section-list-holder-without-overflow {
        .section-list-item {
          background: #f2f6f6;
          border-radius: 12px;
          padding: 15px;
          margin-bottom: 15px;
          &:hover {
            background: #b4dbdb;
            cursor: pointer;
          }

          .section-list-subtitle {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: rgba(5, 36, 37, 0.87);
          }

          .section-list-subtitle-description {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: rgba(5, 36, 37, 0.87);
          }

          .header-button {
            .section-amount {
              width: 75px;
              height: 42px;
              font-weight: 500;
              font-size: 28px;
              line-height: 42px;
              color: #136162;
            }

            .btn {
              &:hover {
                background: transparent;
              }
            }
          }

          .section-list-header {
            h5 {
              color: #1c4f51;
              margin: 0;
            }

            .section-list-title {
              color: #136162;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        &.report-holder {
          .section-header {
            h6 {
              font-size: 32px;
              color: #136162;

              span {
                font-size: 16px;
              }
            }
          }

          .section-list-header {
            h5 {
              color: rgba(5, 36, 37, 0.6);
              font-size: 16px;
            }
          }
        }

        &.inspection-sidbar {
          margin-top: 0;
          max-height: 100%;

          h6 {
            font-size: 12px;
            color: #136162;
          }

          h4 {
            font-weight: 500;
            color: rgba(5, 36, 37, 0.87);
            margin-bottom: 10px;
            font-size: 20px;
          }

          .inspection-name {
            color: rgba(5, 36, 37, 0.87);
            font-weight: normal;
          }

          .grade {
            .grade-label {
              font-size: 10px;
              color: rgba(5, 36, 37, 0.38);
            }

            .grade-value {
              color: #136162;
              font-size: 36px;
            }
          }
        }
      }
    }
  }
}

//-------------end of overview css------------

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tablet-view-sticky-img-nav {
    position: sticky;
    top: 0;
    background: white;
    padding: 15px 0 0;
    z-index: 1;
  }
}
@media screen and (max-width: 1024px) {
  .border-section {
    border-bottom: 1px solid #e1e5e5;
    margin-top: 22px;
  }
}
.section-details-header {
  .section-header {
    h5 {
      color: rgba(5, 36, 37, 0.6);
      letter-spacing: 0.15px;
      font-weight: 500;
      margin: 0;
    }

    .section-title {
      color: rgba(5, 36, 37, 0.87);
    }
  }
}
.area-table {
  .area-table-title {
    background: #f2f6f6;
    color: rgba(5, 36, 37, 0.87);
  }

  .area-table-header {
    color: rgba(5, 36, 37, 0.38);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    th {
      border-bottom: 1px solid rgba(5, 36, 37, 0.12);
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(5, 36, 37, 0.12);
      font-weight: 400;
      font-size: 16px;
    }
  }
}

//------------------------InspectionDetails file css----------------------------
.people-details-holder,
.inspections-details {
  padding: 15px;
  max-width: calc(100vw - 800px);
  overflow: auto;
  @media screen and (max-width: 768px) {
    padding: 0 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 100%;
    padding: 0 0;
  }

  .header {
    margin-bottom: 15px;
    color: rgba(5, 36, 37, 0.6);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      text-align: center;
      box-shadow: inset 0px -2px 0px #136162;
      color: #136162;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      padding: 15px;
      margin: 0 0 24px 0;
    }

    span {
      color: rgba(5, 36, 37, 0.87);
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        text-align: center;
        color: #136162;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        padding: 15px 0;
        margin: unset;
      }
    }

    .section-title {
      color: rgba(5, 36, 37, 0.87);
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 16px;
      line-height: 24px;
    }

    .people-header-btn {
      background: rgba(5, 36, 37, 0.08);
      border-radius: 16px;
      box-shadow: none !important;
    }

    .active-filter {
      background: #6cb2b2;
      color: white !important;
    }
  }

  .accordion {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      &:last-child {
        padding-bottom: 50px !important;
      }
    }

    .accordion-item {
      .accordion-header {
        .accordion-button {
          border: 0;
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            &:last-child {
              padding: 10px !important;
            }
          }

          &:focus {
            box-shadow: none;
          }

          &:after {
            margin-top: -50px;
          }

          &:not(.collapsed) {
            color: #212529;
            background-color: transparent;
          }

          .inspections-sub-details {
            .date-label {
              font-size: 12px;
              font-weight: 500;
              color: #136162;
            }

            h4 {
              font-weight: 500;
              color: rgba(5, 36, 37, 0.87);
              margin: 5px 0 8px;
              font-size: 20px;
            }

            .inspection-name {
              color: rgba(5, 36, 37, 0.87);
              font-weight: normal;
            }
          }

          .points-holder {
            .points-label {
              font-size: 10px;
              color: rgba(5, 36, 37, 0.38);
              letter-spacing: 1.5px;
              text-transform: uppercase;
            }

            .points-value {
              color: #136162;
              font-size: 36px;
            }
          }
        }
      }

      .accordion-collapse {
        border: 0;

        .inspections-table {
          .header-block {
            color: rgba(5, 36, 37, 0.38);
            font-size: 14px;
            font-weight: 500;

            .header-label {
              flex: 1 0 0;

              &:nth-child(1) {
                @media screen and (max-width: 768px) {
                  text-align: start;
                }
              }

              &:nth-child(3) {
                max-width: 200px;
                text-align: center;
                @media screen and (max-width: 1024px) {
                  text-align: end;
                  max-width: 100px;
                }
              }

              &:nth-child(2) {
                max-width: 100px;
                text-align: center;
              }
            }
          }

          .content-block {
            ul {
              padding: 0;
              list-style: none;

              li {
                .content-text {
                  display: flex;
                  color: rgba(5, 36, 37, 0.87);
                  border-bottom: 1px solid rgba(5, 36, 37, 0.12);

                  .content-parameter {
                    flex: 1 0 0%;
                    text-align: start;
                  }

                  .content-result {
                    flex: 1 0 0%;
                    max-width: 100px;
                    text-align: center;
                    color: rgba(46, 210, 92, 0.87);
                  }

                  .content-points {
                    flex: 1 0 0%;
                    max-width: 200px;
                    text-align: center;
                    font-weight: 500;
                    @media screen and (max-width: 1024px) {
                      text-align: end;
                      max-width: 100px;
                    }
                  }
                }

                &.fail {
                  .content-result {
                    color: rgba(249, 34, 21, 0.87);
                  }

                  .content-text {
                    border-bottom: 0;
                  }
                }

                &:last-child {
                  .content-text {
                    border-bottom: 0;
                  }
                }

                .content-inner-block {
                  padding: 0 32px;
                  color: rgba(5, 36, 37, 0.6);
                  font-size: 14px;
                  border-bottom: 1px solid rgba(5, 36, 37, 0.12);
                  padding-bottom: 15px;

                  h6 {
                    color: rgba(5, 36, 37, 0.87);
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                  }

                  .image-holder {
                    overflow-x: auto;

                    .image-block {
                      border-radius: 12px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//------------------------End of InspctionDetails css-----------------------------

.active-item {
  background: #b4dbdb !important;
}

//Room.js mobile design css
.section-header-modal {
  h5 {
    font-size: 34px;
    line-height: 42px;
  }
}
.report-header-modal {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  margin-top: 17px;
  border-bottom: 1px solid rgba(5, 36, 37, 0.12);

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(5, 36, 37, 0.38);
  }
}
.section-list-modal {
  padding: 12px 0;
  border-bottom: 1px solid rgba(5, 36, 37, 0.12);
  display: flex;
  justify-content: space-between;

  div:nth-child(2) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

//navbar css

.overview-navbar {
  padding: 0 !important;
  //border-bottom: 1px solid #e1e5e5;

  .navbar-container {
    padding: 0;
    .navbar-toggler {
      display: none;
    }
    @media screen and (min-width: 1025px) {
      .navbar-collapse {
        display: block;
      }
    }
    @media screen and (max-width: 1024px) {
      .nav-tabs-wrapper {
        display: block;
      }
      .navbar-toggler {
        display: block;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin: 22px 0 !important;
    }
    @media screen and (max-width: 1024px) {
      border: 1px solid #e1e5e5;
      padding: 7px 16px;
      margin: 24px 16px;
      border-radius: 4px;

      .navbar-header {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(5, 36, 37, 0.6);
        font-style: normal;
        text-transform: capitalize;
      }

      .toggle-btn {
        border-color: white;
        color: white;
      }

      .nav-tabs {
        border-bottom: none !important;
      }
      .nav-tabs-wrapper {
        flex-direction: column;
        align-items: flex-start;

        button {
          padding: 8px 0 !important;
          border-bottom: none !important;
          text-transform: unset !important;
          &:hover {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}
.sds-screen {
  overflow: auto;
  .table-header {
    @media screen and (max-width: 768px) {
      padding-top: 36px;
      border-top: 1px;
    }
  }

  table {
    @media screen and (max-width: 1024px) {
      margin-bottom: 90px;
    }
    thead {
      th {
        max-width: 50%;
        width: 50%;
        border-bottom: none;
        color: rgba(5, 36, 37, 0.6);
        letter-spacing: 0.1px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        @media screen and (max-width: 1024px) {
          border-left: hidden;
          border-right: hidden;
          border-top: hidden;
        }
        &:last-child {
          text-align: center;
          @media screen and (max-width: 1024px) {
            border-right: hidden;
            text-align: right;
          }
        }
      }
    }
    tbody {
      font-size: 16px;
      line-height: 24px;
      color: rgba(5, 36, 37, 0.87);
      td {
        font-weight: 500;
        letter-spacing: 0.15px;
        border-top: none;
        &:first-child {
          @media screen and (max-width: 1024px) {
            border-left: hidden;
            border-right: hidden;
          }
          &:hover {
            background-color: #b4dbdb;
          }
        }
        &:last-child {
          text-align: center;
          letter-spacing: 0.25px;
          font-weight: normal;
        }
      }
    }
  }
}
.list-content,
.list-content-title {
    color: #136162;
    font-size: 30px;
    position: static;
    line-height: 20px;
  }
.report-header {
  display: flex;
  justify-content: space-between;
  padding: 11px 15px 5px;

  

  .list-label {
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(5, 36, 37, 0.38);
    font-weight: 500;
  }

  .section-list-label {
    color: rgba(5, 36, 37, 0.6);
    line-height: 20px;
    letter-spacing: 0.25px;
    text-transform: none;
  }
}
textarea.form-control.inspection-note-textarea{
  height: 90px;
}
.section-right-title {
  color: rgba(5, 36, 37, 0.6);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.inspection-save-question {
  height: 5rem;
}

.section-image-holder {
  background: #f2f6f6;
  border-radius: 8px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 8px;

  .section-image {
    height: 140px;
    border-radius: 8px;
    margin-right: 8px;
  }
}
.section-image-title {
          font-size: 18px;
          line-height: 24px;
        }