// Place all the styles related to the device controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

$base-color: rgb(19, 97, 98);
$placeholder-color:  rgba(5, 36, 37, 0.6);
:root {

--bs-primary: $base-color;
--bs-primary-rgb: 19, 97, 98;
}
.btn {
  --bs-btn-disabled-bg: #136162; 
  --bs-btn-disabled-border-color: #136162;
}
.dropdown-item.active {
  background-color: $base-color ;
}
.dropdown-item.active button,
.dropdown-item.active a,
.dropdown-item.active i {
  color: white !important;
}
.btn:disabled {
  --bs-btn-disabled-color: #fff;
}
html,
body {
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
  //overflow: hidden;
  //overflow: scroll;

  ::-webkit-scrollbar {
    width: 0 !important;
  }
  a {
    color: $base-color;
    &:hover {
      color: $base-color;
    }
  }
  .form-control,
  .form-select {
    font-size: 14px;
  }
  input::placeholder {
    color: $placeholder-color;
  }
  .navigation-btn {
    .skip-btn {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 100px;
      height: 48px;
    }
  }
  .form-floating > {
    .password-holder {
      position: relative;
    }
    .form-control {
      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        opacity: 9;
        transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
        background: #fff;
        margin-left: 5px;
        padding: 0 5px;
        height: auto;
        color: rgba(5, 36, 37, 0.6);
        border-radius: 4px;
      }
    }

    .form-select ~ label {
      opacity: 9;
      transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);
      background: #fff;
      margin-left: 5px;
      padding: 0 5px;
      height: auto;
      color: rgba(5, 36, 37, 0.6);
      border-radius: 4px;
    }
  }

  .form-control:focus,
  .form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
  }
  .toolsWrapper {
    .form-floating select {
      font-size: 16px;
      background-image: url('./components/Dashboard/assets/DropDownArrow.svg');
      background-repeat: no-repeat;
      background-position: 95% 50%;
    }
  }
  .form-floating > {
    .form-control,
    .form-select {
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: 0.8rem;
        padding-bottom: 0.625rem;
      }
    }
    .eye-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    label {
      padding: 0.6rem 0.75rem;
      color: rgba(5, 36, 37, 0.6);
    }

    .form-control,
    .form-select {
      height: 48px;
      padding: 1rem 0.75rem;
    }
  }
  .form-check {
    .form-check-label {
      color: $base-color;
    }
  }
  .password-holder {
    position: relative;
    .form-control {
      padding-right: 44px;
    }
    .eye-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
  .btn-primary:active,
  .btn-primary:focus,
  .btn-primary {
    background-color: $base-color;
    border-color: $base-color;
    text-transform: uppercase;
    min-height: 48px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
    &:hover {
      background: #fff;
      color: $base-color;
      border-color: $base-color;
    }
  }
  .btn-outline-primary:active,
  .btn-outline-primary:focus,
  .btn-outline-primary {
    background-color: #fff;
    color: $base-color;
    border-color: #ced4da;
    text-transform: uppercase;
    min-height: 48px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
    &:hover {
      background: $base-color;
      color: #fff;
      border-color: $base-color;
    }
  }
  .btn-light {
    color: $base-color;
    text-transform: uppercase;
    min-height: 48px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $base-color;
      color: #fff;
      border-color: $base-color;
    }
  }
  .btn-small:active,
  .btn-small:focus,
  .btn-small {
    min-height: 36px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    font-weight: 500;
    text-transform: uppercase;
  }  
  .form-image {
    height: 100vh !important;
  }
  .custom-loader {
    width: 50px;
    height: 50px;
    color: #136162;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .custom-modal {
    padding: 20px;
    .custom-modal-title {
      font-size: 34px;
      line-height: 42px;
      text-align: center;
      color: rgba(5, 36, 37, 0.87);
      margin-bottom: 40px;
    }
    .toolsWrapper {
      height: 420px;
      .row {
        height: 80px;
      }
    }
    .title-room-type {
      font-size: 24px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .index-of-room {
      font-size: 24px;
      column-gap: 13px;
      div {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .custom-modal {
      padding: 0;
      height: 100%;
      form {
        height: calc(100% - 90px);
      }
    }
  }
  .list-group-item:hover {
    background: #b4dbdb !important;
  }

  //--Nav bar---
  .nav-tabs {
    .nav-link {
      text-transform: uppercase;
      flex: auto;
      font-size: 14px;
      color: rgba(5, 36, 37, 0.6);
      padding: 0.8rem 0.5rem;
      font-weight: 500;

      &:hover,
      &.active {
        color: #136162;
        border-bottom: 2px solid;
        border-color: #fff #fff #136162;
      }
    }
  }
  //End of Nav bar css------
}
.accordion-item + .accordion-item {
  margin-top: 16px;
}
#sectionsAccordion .accordion-item .accordion-header .accordion-button {
  border-radius: 6px !important ;
  box-shadow: none;
  background-color: rgba(5, 36, 37, 0.87);
  color: white;
  &, 
  * {
    color: white;
  }
}
.accordion-item {
  .accordion-button::after {
    color: currentColor;
    content: '\f107';
    font-family: 'FontAwesome';
    background: none;
    text-align: center;
    background: none;

  }
}

.app-container {
  display: flex;
  flex-flow: column nowrap;
  > * {
    flex: 0 1 auto;
  }
  > .app-main-content {
    flex: 1 1 auto;
  }
}

.sidebar-layout-container {
  > .sl-container {
    overflow: auto;
    max-height: 100%;
  }
  > .sl-sidebar {
    border-right: 1px solid rgba(5,36,37,.12)
  }
}
.sc-dropdown-control [class*="-placeholder"] {
  font-size: 16px;
  color: $placeholder-color;

  
}
.sc-dropdown-control [class*="-Svg"]{
    fill: $base-color !important;
    color: $base-color !important;
}
.sc-add-new-cleaner {
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }

}
ol {
  list-style: none;
  counter-reset: qck12-list-counter;
}
ol li {
  counter-increment: qck12-list-counter;
}
ol li::before {
  content: counter(qck12-list-counter) ". ";
  margin-right: 0.75rem;
  font-weight: bold;
  font-family: 'Roboto' sans-serif;
}
i[class*="fa-"] {
  color: #136162;
}
.btn-outline-primary:focus, 
.btn-outline-primary:active,
.btn-outline-primary:hover  {
  background-color: #136162 !important;
  border-color: #136162 !important;
  color: #fff !important;
  i {

    color: #fff !important;
  }
}

.highlighted-text {
  font-weight: 800;
  color: var(--bs-primary)
}
@media (min-width: 576px) {
  .modal-sm {
      --bs-modal-width: 434px;
  }
}

.dropdown-select > input {
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.btn-unset-height{
  min-height: unset !important;
}

.section-file {
  width: 150px;
  height:150px;
  object-fit: cover;
}

.file-title {
  width: 150px;
  word-wrap: break-word; 
  text-align: center;
}
.scrollable-modal-body{
  overflow-y: auto;
  max-height: calc(100vh - 200px) ;
}