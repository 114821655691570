.navbar-container {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .navbar-toggler,
  .navbar-brand {
    margin: 0 10px;
  }
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: #1a6062;;
    margin-left: 20px;
  }
  
  .nav-link.active {
    color: #1a6062;;
  }
  #basic-navbar-nav{
    justify-content: flex-end;
  }
  .nav-dropdown {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
  }
  @media screen and (min-width: 768px) {
    .last-nav-item {
      margin-right: 20px;
      padding-right: 20px;
    }
  }
  
  .nav-dropdown.active {
    color: #1a6062;;
  }
  
  .nav-dropdown-item {
    font-size: 0.9rem;
  }

  .search-row {
    padding-top: 10px;
    background-color: #f2f2f2;
    align-items: baseline;
    justify-content: center;
  }
 
.col-invite{
    justify-content: flex-end;
}
.search-items {
  height: fit-content;
}

.nav-link:hover i {
color: rgba(0, 0, 0, 0.7);
}