.reset-password {
	.reset-password-holder {
		.banner {
			position: relative;
			.caption-head {
				font-size: 30px;
				font-weight: 300;
				line-height: 35px;
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 80px 5%;
				color: #fff;
				background: #13616399;
				h2 {
					font-size: 50px;
					font-weight: 300;
					line-height: 60px;
					margin-bottom: 0;
				}
			}
		}
		.form-holder {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			padding: 50px 0; 
			flex-direction: column;
			h2{
				color: rgba(5, 36, 37, 0.87);
				font-weight: normal;
				font-size: 45px;
				margin-bottom: 35px;
				text-align: center;
			}
			.form-box {
				max-width: 295px;
				width: 100%;
			}
		}
	}
}

.modal-header {
	align-items: center !important;
  }
  
  .reset-password_success {
	position: fixed;
	z-index: 10;
	width: 100%;
	padding-top: 30px;
  }
  
  
  
  .modal {
	position: absolute;
	overflow-y: hidden;
	
	.modal-holder {
	  // max-width: 417px;
	  // margin: auto auto;
	  max-width: 417px;
	  margin: auto;
	  position: absolute;
	  height: fit-content;
	  bottom: 0;
	  top: 0;
	  right: 0;
	  left: 0;
	  .modal-content {
		border-radius: 12px;
		box-shadow: 0px 12px 17px #00000024, 0px 5px 22px #0000001f,
		  0px 7px 8px #00000033;
		.modal-body {
		  max-width: 413px;
		  padding: 40px;
		  .modal-title {
			color: rgba(5, 36, 37, 0.87);
			font-weight: normal;
			font-size: 34px;
			margin-bottom: 15px;
			text-align: center;
		  }
		  .modal-details {
			max-width: 335px;
			text-align: center;
			color: rgba(5, 36, 37, 0.87);
			margin-bottom: 30px;
		  }
		}
	  }
	}
  }
  .tc-link {
	color: #1a6062;
	text-transform: capitalize;
	cursor: pointer;
 }
 #termsAndConditions:checked {
	background-color: #1a6062; /* Change this to the desired color */
  }