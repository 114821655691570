.setting-con {
  width: calc(100% - 100px);
  margin: 0rem auto;
  @media screen and (max-width: 1500px) {
    width: calc(100% - 80px);
  }
  @media screen and (max-width: 1200px) {
    width: calc(100% - 50px);
  }
  @media screen and (max-width: 900px) {
    width: calc(100% - 30px);
  }
  @media screen and (max-width: 800px) {
    width: calc(100% - 20px);
  }
}

.password-card {
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.lock-image {
  width: 155px;
  height: 150px;
}



.btn-primary {
  min-height: 10px !important;
  text-transform: capitalize !important;
}

.daily-report-con{
  @media screen and (max-width: 767px) {
   margin-top: 1.5rem;
  }

  .report-switch-con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: "0.25px solid #CED4D9";
    .title {
      width: 80%;
    }
  }

}