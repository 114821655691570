.inspection-report-search {
  display: flex;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: flex-start;
  padding-top: 16px;
}
.search-items {
  height: fit-content;
}
.search-button {
  padding: 6px 12px;
}