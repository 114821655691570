.modal-content {
    border-radius: 4px;
  }
  
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .modal-title {
    font-weight: bold;
  }
  
  .modal-body {
    padding-top: 0;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control {
    margin-bottom: 15px;
  }
  
  .modal-footer {
    border-top: none;
    padding-top: 0;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0069d9;
  }