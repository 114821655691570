.confirmation-modal {
  .modal-title{

  }
    .modal-content {
      border-radius: 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  
    .modal-body {
      padding: 0.5rem 1rem;
    }
  
    .modal-footer {
      padding: 0.5rem 1rem;
      justify-content: flex-end;
  
      .btn {
        margin-left: 0.5rem;
      }
    }
   .btn .btn-light{
      min-height: unset !important;
    }
  }
  