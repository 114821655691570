.image {
  height: 300px;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}
.react-responsive-modal-closeButton {
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }
}
.upload-image {
  width: 100%;
  margin: 0 auto 10px auto;
  height: 300px;
  object-fit: contain;
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}

@media screen and (max-width: 576px) {
  @keyframes customEnterModalAnimation {
    0% {
      transform: translateY(100%);
      scroll-behavior: smooth;
    }
    100% {
      transform: translateY(0);
      scroll-behavior: smooth;
    }
  }
  @keyframes customLeaveModalAnimation {
    100% {
      transform: translateY(100%);
      scroll-behavior: smooth;
    }
    0% {
      transform: translateY(0);
      scroll-behavior: smooth;
    }
  }
}

.react-responsive-modal-modal {
  width: 560px;
  border-radius: 12px;
  text-align: center !important;
  margin: 0;

  @media screen and (max-width: 576px) {
    bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: calc(100vh - 200px);
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 707px;
  }
}

.react-responsive-modal-container {
  @media screen and (max-width: 576px) {
    padding: 16px !important;
  }
  .react-responsive-modal-desktop {
    @media screen and (max-width: 576px) {
      margin: 0;
      position: unset;
      height: max-content;
      border-radius: 12px;

      .react-responsive-modal-closeButton {
        display: none;
      }
    }
  }
}
.react-responsive-modal-max-content-width {
  @media screen and (max-width: 576px) {
    height: max-content !important;
  }
}

.tablet-view-delete-modal {
  @media screen and (min-width: 576px) and (max-width: 1024px) {
    width: 343px !important;
  }
}
.tablet-view-Add-section-modal {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 407px !important;
  }
}
.tablet-view-cleaner-modal {
  @media screen and (min-width: 407px) and (max-width: 768px) {
    width: 375px !important;
  }
}
.tablet-view-sds-modal {
  @media screen and (min-width: 439px) and (max-width: 1024px) {
    width: 407px !important;
  }
}
.admin-info-modal {
  width: max-content;
  padding: 20px;
}

.deleteImage {
  width: 120px;
}
.deleteContent {
  text-align: center;
}
.deleteText {
  font-size: larger;
  margin-top: 30px;
  color: #136162;
}
//modalbox two buttons
.deleteButtons {
  padding-top: 42px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    width: 143px;
  }
  #cancel-btn {
    background-color: white;
    color: #136162;
  }
}
